import { Module } from 'vuex';
import { State } from '@/store/models';
import loans from '@/store/modules/loans';
import premiumLoans from '@/store/modules/premiumLoans';
import authModal from './authModal';
import idin from './idin';
import payment from './payment';
import investments from './investments';
import payments from './payments';
import dividends from './dividends';
import assets from './assets';
import identificationChecks from './identificationChecks';
import downloads from './downloads/downloads';
import checkout from './checkout';

export default <{ [key: string]: Module<any, State> }>{
  idin,
  payment,
  investments,
  payments,
  dividends,
  loans,
  premiumLoans,
  assets,
  identificationChecks,
  downloads,
  authModal,
  checkout,
};
