import to from 'await-to-js';
import firebase from 'firebase';
import Vue from 'vue';
import { ActionContext, Module } from 'vuex';
import { Loan } from '@/store/models/Loan';
import { InitialStateSlice, State, StateSlice } from '@/store/models';
import { db } from '@/firebase';

const GET_LOANS_ERROR = 'GET_LOANS_ERROR';
const GET_LOANS_SUCCESS = 'GET_LOANS_SUCCESS';
const GET_LOANS_PROCESSING = 'GET_LOANS_PROCESSING';

export default <Module<StateSlice<Loan[]>, State>>{
  state: new InitialStateSlice(),
  mutations: {
    [GET_LOANS_ERROR](state: StateSlice, error: any): void {
      Vue.set(state, 'status', 'error');
      Vue.set(state, 'error', error.message || 'Something went wrong');
    },
    [GET_LOANS_SUCCESS](state: StateSlice, payload: any): void {
      Vue.set(state, 'status', 'success');
      Vue.set(state, 'payload', payload);
    },
    [GET_LOANS_PROCESSING](state: StateSlice): void {
      Vue.set(state, 'status', 'processing');
      Vue.set(state, 'payload', null);
    },
  },
  actions: {
    // get loans by asset
    async getLoansByAsset({ commit }: ActionContext<StateSlice, State>, { id }: any): Promise<void> {
      commit(GET_LOANS_PROCESSING);

      const [getLoanError, getLoanSuccess] = await to(
        db.collection('assets')
          .doc(id)
          .collection('loans')
          .where('deleted', '==', false)
          .where('published', '==', true)
          .get(),
      );

      if (getLoanError) {
        return commit(GET_LOANS_ERROR, getLoanError);
      }

      const loans = getLoanSuccess?.docs.map((doc): firebase.firestore.DocumentData => ({
        ...doc.data(),
        id: doc.id,
      }));

      return commit(GET_LOANS_SUCCESS, loans);
    },
  },
};
