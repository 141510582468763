import Vue from 'vue';
import Router, { Route, RouteConfig } from 'vue-router';
import { auth } from '@/firebase';
import { IdentCheckoutRouteNames, IdentifcationPath, steps } from '@/helpers/checkout';
import { defaultLanguage, isValidLang, Language, loadLanguageAsync, localStorageKey } from '@/i18n';
import moduleRoutes from '@/modules/routes';
import { AuthComponents } from '@/store/models';
// eslint-disable-next-line import/extensions
import { CheckoutStepNames, Step } from '@/store/models/checkout.ts';
import { glossary, idinActive } from '../../whitelabel.config';

Vue.use(Router);

/**
 * Prefixing routes' paths to handle language
 */
const withPrefix = (
  prefix: string,
  routes: RouteConfig[],
): RouteConfig[] => routes.map((route): RouteConfig => {
  // Avoiding mutations
  const clonedRoute = { ...route };
  // Every route except for '/'
  if (clonedRoute.path !== '/') {
    clonedRoute.path = prefix + clonedRoute.path;
  }
  return clonedRoute;
});

const mainRoutes: RouteConfig[] = [
  {
    path: '/',
    redirect: '/properties',
  },
  {
    path: '/cookie-policy',
    component: (): Promise<object> => import(/* webpackChunkName: "cookie" */ '@/components/cookie-policy/CookiePolicy.vue'),
  },
  {
    path: '/login',
    component: (): Promise<object> => import(/* webpackChunkName: "auth" */ '@/components/common/auth/auth-page/AuthPage.vue'),
    props: { initWithType: AuthComponents.Login },
    meta: {
      requiresLogout: true,
    },
  },
  {
    path: '/checkout/investment/:id',
    component: (): Promise<object> => import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
    props: { initWithType: CheckoutStepNames.Investment },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/checkout/status/:id/:paymentId',
    component: (): Promise<object> => import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
    name: 'checkoutStatus',
    props: { initWithType: CheckoutStepNames.Status },
    meta: {
      requiresAuth: true,
    },
  },
  ...steps.reduce((routes: RouteConfig[], step: Step): RouteConfig[] => {
    let selectedRoutes = [...routes];
    if (step.name === CheckoutStepNames.Identification) {
      selectedRoutes = selectedRoutes.concat([
        {
          path: `/checkout/${IdentifcationPath}/`,
          component: (): Promise<object> => import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
          props: { initWithType: CheckoutStepNames.Identification, header: false },
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: ':investmentId/',
              name: IdentCheckoutRouteNames.MAIN,
              component: (): Promise<object> =>
                import(/* webpackChunkName: "identification" */ '@/modules/identification/components/Identification.vue'),
              props: { header: false },
              children: [
                ...idinActive
                  ? [{
                  path: 'idin/',
                  name: IdentCheckoutRouteNames.IDIN,
                  component: (): Promise<object> =>
                    import(/* webpackChunkName: "identification" */ '@/modules/identification/components/idin/IdentificationIdin.vue'),
                  props: { header: false },
                }] : [],
                {
                  path: 'world/',
                  name: IdentCheckoutRouteNames.WORLD,
                  component: (): Promise<object> =>
                    import(/* webpackChunkName: "identification" */ '@/modules/identification/components/world/IdentificationWorld.vue'),
                  props: { header: false },
                },
                {
                  path: 'business/',
                  name: IdentCheckoutRouteNames.BUSINESS,
                  component: (): Promise<object> =>
                    import(/* webpackChunkName: "identification" */ '@/modules/identification/components/business/IdentificationBusiness.vue'),
                  props: { header: false },
                },
              ],
            },
          ],
        },
      ]);
    }
    if (step.name === CheckoutStepNames.Terms) {
      selectedRoutes = selectedRoutes.concat([
        {
          path: '/checkout/legal/:id',
          component: (): Promise<object> => import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
          props: { initWithType: CheckoutStepNames.Terms },
          meta: {
            requiresAuth: true,
          },
          name: CheckoutStepNames.Terms,
        },
      ]);
    }
    if (step.name === CheckoutStepNames.Questionnaire) {
      selectedRoutes = selectedRoutes.concat([
        {
          path: '/checkout/questionnaire/:id',
          component: (): Promise<object> => import(/* webpackChunkName: "checkout" */ '@/components/checkout/Checkout.vue'),
          props: { initWithType: CheckoutStepNames.Questionnaire },
          meta: {
            requiresAuth: true,
          },
          name: CheckoutStepNames.Questionnaire,
        },
      ]);
    }
    return selectedRoutes;
  }, []),
  {
    path: '/register',
    component: (): Promise<object> => import(/* webpackChunkName: "auth" */ '@/components/common/auth/auth-page/AuthPage.vue'),
    props: { initWithType: AuthComponents.Register },
    meta: {
      requiresLogout: true,
    },
  },
  {
    path: '/reset',
    component: (): Promise<object> => import(/* webpackChunkName: "auth" */ '@/components/common/auth/auth-page/AuthPage.vue'),
    props: { initWithType: AuthComponents.Reset },
    meta: {
      requiresLogout: true,
    },
  },
  {
    path: '/auth-verification',
    component: (): Promise<object> => import(/* webpackChunkName: "auth" */ '@/components/common/auth/auth-page/AuthPage.vue'),
    props: { initWithType: AuthComponents.AuthVerification },
    meta: {
      requiresLogout: true,
    },
  },
  {
    path: '/error/:errorType?',
    component: (): Promise<object> => import(/* webpackChunkName: "error" */ '@/components/common/StaticErrors/StaticErrors.vue'),
  },
  {
    path: '/activate/:activateType?/:id?',
    component: (): Promise<object> => import(/* webpackChunkName: "error" */ '@/components/common/activate/Activate.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account',
    component: (): Promise<object> => import(/* webpackChunkName: "account" */ '@/components/account/Account.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        component: (): Promise<object> => import(/* webpackChunkName: "account" */ '@/components/account/dashboard/AccountDashboard.vue'),
      },
      {
        path: 'settings',
        name: 'settings',
        component: (): Promise<object> => import(/* webpackChunkName: "account" */ '@/components/account/settings/AccountSettings.vue'),
        redirect: '/account/settings/identification',
        children: [
          {
            path: 'details',
            name: 'settings-details',
            component: (): Promise<object> =>
              import(/* webpackChunkName: "account" */ '@/components/account/settings/details/AccountSettingsDetails.vue'),
          },
          {
            path: 'identification',
            name: 'settings-identification',
            component: (): Promise<object> =>
              import(/* webpackChunkName: "account" */ '@/components/account/settings/identification/AccountSettingsIdentification.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/properties',
    name: 'properties',
    component: (): Promise<object> => import(/* webpackChunkName: "property" */ '@/components/properties/property/Property.vue'),
  },
  {
    path: '/property/:id',
    name: 'property',
    component: (): Promise<object> => import(/* webpackChunkName: "property" */ '@/components/properties/detail/PropertyDetail.vue'),
    children: [
      {
        path: 'loan/:loanId',
        name: 'loanNotUsed', // this is needed after there are more assets
        component: (): Promise<object> => import(/* webpackChunkName: "property" */ '@/components/properties/loan/Loan.vue'),
      },
    ],
  },
  {
    path: '/loan/:assetId/:loanId', // since there is only one asset now we directly go here
    name: 'loan',
    component: (): Promise<object> => import(/* webpackChunkName: "property" */ '@/components/properties/loan/Loan.vue'),
  },
];

const errorRedirectRoute: RouteConfig = {
  path: '/*',
  beforeEnter: (to, from, next): void => {
    const navigationLang = to.params.lang;
    if (navigationLang) {
      return next(`/${navigationLang}/error/404`);
    }
    return next('/error/404');
  },
};

// Expanding all the routes from the modules into one array
const moduleRoutesAsArray = Object.entries(moduleRoutes).reduce(
  (
    previousArray,
    nextRouteKeyPair,
  ): RouteConfig[] => previousArray.concat(nextRouteKeyPair[1]), [] as RouteConfig[],
);

// Filtering all the routes that are already as module routes (by path)
const filteredMainRoutes = mainRoutes.filter((route): boolean => !moduleRoutesAsArray.some((mRoute): boolean => mRoute.path === route.path));

// First the main routes with replicated ones with moduleRoutes removed
// Then the routes from the modules
// Then the generic error redirect
const finalRoutes = [...filteredMainRoutes, ...moduleRoutesAsArray, errorRedirectRoute];

const router = new Router({
  // Mixing all routes into one final array
  routes: withPrefix('/:lang?', finalRoutes),
  scrollBehavior(to, from, savedPosition): any {
    return { x: 0, y: 0 };
  },
  // History is cleaner and a must for prerendering
  mode: 'history',
});

// Checking all the meta requirements for the going (next) page
const checkMeta = (to: Route, requires: string[]): { [key: string]: boolean } => {
  const metaResult = {};

  requires.forEach((req): void => {
    metaResult[req] = to.matched.some((record): boolean => record.meta[req]);
  });

  return metaResult;
};

router.beforeEach((to, from, next): void => {
  const { currentUser } = auth;
  const meta = checkMeta(to, ['requiresAuth', 'requiresIdin', 'requiresLogout']);

  const pagesToBeChecked = ['/property'];
  const redirectToWhenLoggingIn = (path: string): boolean => pagesToBeChecked.some((page): boolean => path.startsWith(page));

  // Setting language according to where we go:
  // If user wants to change to specific lang (to)
  // If user remains in same lang (from)
  // User is in no lang mode (defaultLanguage)
  const { lang } = to.params;
  const { lang: previousLang } = from.params;
  const localStorageLang = localStorage.getItem(localStorageKey);
  const toLang = lang || previousLang || localStorageLang || defaultLanguage;
  const fullToLang = toLang ? `/${toLang}` : '';
  if (isValidLang(toLang)) {
    loadLanguageAsync(toLang);
  } else {
    next(to.path.replace(toLang, defaultLanguage));
    return;
  }

  switch (true) {
    // Pages that the user needs to be logged out (or email not verified) to see them
    case meta.requiresLogout && currentUser && currentUser.emailVerified:
      next({ path: '/properties' });
      break;
    // Pages the user needs to be logged in && email activated to see them
    case meta.requiresAuth && (!currentUser || !currentUser.emailVerified):
      if (from.fullPath === '/') { // no previous page we can directly redirect to the login
        next({ path: '/login' });
      } else { // otherwise we open the modal
        router.app.$store.dispatch('openModal', { type: AuthComponents.Login, routeTo: to.fullPath });
        next(false);
      }
      break;
    // Pages that need to be redirected to after logging in
    case to.path.startsWith('/login') && redirectToWhenLoggingIn(from.path) && Object.keys(to.query).length === 0:
      if (from.fullPath === '/') {
        next({ path: '/login' });
      } else {
        router.app.$store.dispatch('openModal', { type: AuthComponents.Login, routeTo: to.fullPath });
        next(false);
      }
      break;
    default: {
      const redirectTo = lang ? to.fullPath : `${fullToLang}${to.fullPath}`;
      if (to.fullPath !== redirectTo) {
        next({ path: redirectTo });
        return;
      }
      next();
    }
  }
});

export default router;
