import { Module } from 'vuex';
import { formatNumber } from '@/filters/number';
import { State } from '@/store/models';
import { Asset } from '@/store/models/asset';
import { Investment } from '@/store/models/investment';
import BigNumber from 'bignumber.js';
import getters from '../getters';

export interface InvestmentsArray<T> extends Array<T> {
  totalLength?: number;
}

export default <Module<Investment[], State>>{
  state: [],
  mutations: {},
  actions: {},
  getters: {
    investmentHasPaidPayments: (state, getters): Function => (id: string): boolean => !!getters.getPaymentsByInvestmentId(id).length,
    investmentsLoadMore: (state, getters): Function => (position: number): Investment[] => {
      const tempInvestments = getters.getInvestmentsNonDeletedAsset as Investment[];
      const paidInvestments = tempInvestments.filter((investment: Investment): boolean => getters.investmentHasPaidPayments(investment.id));
      const investments: InvestmentsArray<any> = paidInvestments.slice(0, position > tempInvestments.length ? tempInvestments.length : position);

      investments.totalLength = paidInvestments.length;

      return investments;
    },
    // Since we cannot do a proper 'join' to get investments with conditioned asset, we need to do it client side
    getInvestmentsNonDeletedAsset: (state): Investment[] => state.filter(
      (investment): boolean => !!investment.asset.id && !(investment.asset as Asset).deleted && (investment.asset as Asset).published,
    ),
    getInvestmentById: (state): Function =>
      (id: string): Investment | undefined =>
        state.find((investment): boolean => investment.id === id),
    getInvestmentByAsset: (state): Function =>
      (assetId: string): Investment | undefined =>
        state.find((investment): boolean => investment.asset.id === assetId),
    // Get the number of investments that have at least one paid payment
    getLengthPaidInvestments: (state, getters): number => state.filter(
      (investment): boolean => getters.investmentHasPaidPayments(investment.id),
    ).length,

    getInvestmentsNonDeletedPremiumAsset: (state, getters): Investment[] => getters.getInvestmentsNonDeletedAsset.filter(({ asset }): boolean => (asset as Asset).premium),
    getInvestmentsNonDeletedNonPremiumAsset: (state, getters): Investment[] => getters.getInvestmentsNonDeletedAsset.filter(({ asset }): boolean => !(asset as Asset).premium),

    getTotalInvested: (state): number =>
      state.reduce((accumulator, investmentB): BigNumber => accumulator.plus(investmentB.paidEuroTotal || 0), new BigNumber(0)).toNumber(),

    getSharesTotalInvested: (state): number =>
      state.reduce((accumulator, investmentB): BigNumber => accumulator.plus(investmentB.boughtSharesTotal || 0), new BigNumber(0)).toNumber(),

    getPremiumSharesTotalInvested: (state, getters): number =>
      getters.getInvestmentsNonDeletedPremiumAsset
        .reduce((accumulator, investmentB): BigNumber => accumulator.plus(investmentB.boughtSharesTotal || 0), new BigNumber(0)).toNumber(),

    getNonPremiumSharesTotalInvested: (state, getters): number =>
      getters.getInvestmentsNonDeletedNonPremiumAsset
        .reduce((accumulator, investmentB): BigNumber => accumulator.plus(investmentB.boughtSharesTotal || 0), new BigNumber(0)).toNumber(),

    getPremiumTotalInvested: (state, getters): number =>
      getters.getInvestmentsNonDeletedPremiumAsset
        .reduce((accumulator, investmentB): BigNumber => accumulator.plus(investmentB.paidEuroTotal || 0), new BigNumber(0)).toNumber(),

    getNonPremiumTotalInvested: (state, getters): number =>
      getters.getInvestmentsNonDeletedNonPremiumAsset
        .reduce((accumulator, investmentB): BigNumber => accumulator.plus(investmentB.paidEuroTotal || 0), new BigNumber(0)).toNumber(),

    getTotalDividends: (state): number =>
      state
        .reduce((accumulator, investmentB): BigNumber => accumulator.plus(investmentB.totalDividends || 0), new BigNumber(0)).toNumber(),

    getPremiumTotalDividends: (state, getters): number =>
      getters.getInvestmentsNonDeletedPremiumAsset
        .reduce((accumulator, investmentB): BigNumber => accumulator.plus(investmentB.totalDividends || 0), new BigNumber(0)).toNumber(),

    getNonPremiumTotalDividends: (state, getters): number =>
      getters.getInvestmentsNonDeletedNonPremiumAsset
        .reduce((accumulator, investmentB): BigNumber => accumulator.plus(investmentB.totalDividends || 0), new BigNumber(0)).toNumber(),
  },
};
