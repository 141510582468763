import firebase from 'firebase';
import { Module } from 'vuex';
import { State } from '@/store/models';
import { Payment, PaymentStatus } from '@/store/models/investment';
import { Asset } from '../models/asset';

export interface InvestmentsArray<T> extends Array<T> {
  totalLength?: number;
}

// get the paymentDate if paymentDate itself is undefined
export const getPaymentDate = (payment: Payment): firebase.firestore.Timestamp => payment.paymentDateTime ?? payment.updatedDateTime ?? payment.createdDateTime;

export default <Module<Payment[], State>>{
  state: [],
  mutations: {},
  actions: {},
  getters: {
    getPaymentById: (state): Function =>
      (paymentId: string): Payment | undefined => state.find((payment): boolean => payment.id === paymentId),
    getPaymentByAsset: (state): Function =>
      (assetId: string): Payment | undefined =>
        state.find((payment): boolean => payment.asset.id === assetId),
    getPaymentsByInvestmentId: (state): Function =>
      (investmentId: string): Payment[] | undefined => state.filter((payment): boolean => payment.investment.id === investmentId),
    getPaidPaymentsByInvestmentId: (state): Function =>
      (investmentId: string): Payment[] | undefined => state.filter(
        (payment): boolean => payment.investment.id === investmentId && payment.providerData.status === PaymentStatus.Paid && !payment.deleted,
      ).sort((a, b): number => getPaymentDate(b).toMillis() - getPaymentDate(a).toMillis()),
    getPaidPayments: (state): Payment[] => state.filter(
      (payment): boolean => payment.providerData.status === PaymentStatus.Paid,
    ),
    getPaidPaymentsTotal: (state, getters): number => (getters.getPaidPayments as Payment[])
      .filter(({ asset }: Payment): boolean => !(asset as Asset)?.premium === false)
      .reduce((acc, payment): number => {
        const number = Number(payment.providerData.metadata.euroAmount);
        return acc + number;
      }, 0),
    // Get the number of investments that have at least one paid payment
    getLengthPaidPayments: (state): number => state.filter(
      (payment): boolean => payment.providerData.status === PaymentStatus.Paid,
    ).length,
  },
};
