import { xirr } from '@webcarrot/xirr';
import to from 'await-to-js';
import firebase from 'firebase';
import moment from 'moment';
import Vue from 'vue';
import { ActionContext, Module } from 'vuex';
import { Investment, Payment, PaymentProvider, PaymentStatus, PersonalDividend } from '@/store/models/investment';
import { InitialStateSlice, State, StateSlice } from '@/store/models';
import { db } from '@/firebase';
import BigNumber from 'bignumber.js';
import { last } from 'lodash';
import Timestamp = firebase.firestore.Timestamp;
import { Asset } from '../models/asset';

type Transaction = { amount: number, date: Date };

const GET_DIVIDENDS_ERROR = 'GET_DIVIDENDS_ERROR';
const GET_DIVIDENDS_SUCCESS = 'GET_DIVIDENDS_SUCCESS';
const GET_DIVIDENDS_PROCESSING = 'GET_DIVIDENDS_PROCESSING';

export const getPaymentDate = (payment: Payment): Timestamp => payment.paymentDateTime ?? payment.updatedDateTime ?? payment.createdDateTime;

const getIRRPaymentDate = (payment: Payment): Date => {
  const pDate = moment(payment.paymentDateTime!.toDate());

  // the amortisation dates are correct
  if (payment.provider === PaymentProvider.Amortisation) {
    return pDate.toDate();
  }
  // first of the month becomes one day before
  if (pDate.date() === 1) {
    return pDate.subtract(1, 'day').toDate();
  }

  return pDate.toDate();
};

export type PivotedDividend = Required<Pick<PersonalDividend, 'period' | 'personalAmount' | 'type' | 'interestNotice'>>;

export default <Module<StateSlice<PersonalDividend[]>, State>>{
  state: new InitialStateSlice(),
  mutations: {
    [GET_DIVIDENDS_ERROR](state: StateSlice, error: any): void {
      Vue.set(state, 'status', 'error');
      Vue.set(state, 'error', error.message || 'Something went wrong');
    },
    [GET_DIVIDENDS_SUCCESS](state: StateSlice, payload: any): void {
      Vue.set(state, 'status', 'success');
      Vue.set(state, 'payload', payload);
    },
    [GET_DIVIDENDS_PROCESSING](state: StateSlice): void {
      Vue.set(state, 'status', 'processing');
      Vue.set(state, 'payload', null);
    },
  },
  actions: {
    async getDividendsByInvestment({ rootState, commit, dispatch }: ActionContext<StateSlice, State>, { id }: any): Promise<void> {
      commit(GET_DIVIDENDS_PROCESSING);
      const investorRef = db.collection('investors').doc(rootState.user!.id);
      const [getDividendError, getDividendSuccess] = await to(dispatch(
        'bindRef',
        {
          name: 'personalDividends',
          ref: db.collection(`investments/${id}/earnings`)
                 .where('investor', '==', investorRef)
                 .where('deleted', '==', false)
                 .orderBy('period', 'desc'),
        },
      ));
      if (getDividendError) {
        commit(GET_DIVIDENDS_ERROR, getDividendError);
      } else {
        commit(
          GET_DIVIDENDS_SUCCESS,
          getDividendSuccess,
        );
      }
    },
    async getAllDividends({ rootState, commit, dispatch }: ActionContext<StateSlice, State>): Promise<void> {
      commit(GET_DIVIDENDS_PROCESSING);
      const investorRef = db.collection('investors').doc(rootState.user!.id);
      const [getDividendError, getDividendSuccess] = await to(dispatch(
        'bindRef',
        {
          name: 'earnings',
          ref: db.collectionGroup('earnings')
                 .where('investor', '==', investorRef)
                 .where('deleted', '==', false)
                 .orderBy('period', 'desc'),
        },
      ));
      if (getDividendError) {
        commit(GET_DIVIDENDS_ERROR, getDividendError);
      } else {
        commit(
          GET_DIVIDENDS_SUCCESS,
          getDividendSuccess,
        );
      }
    },
    async calculateIRR({ rootState }: ActionContext<StateSlice, State>, { assetId }: any): Promise<number | null> {
      const investmentState = rootState.investments.filter(({ asset }): boolean => asset.id === assetId)[0];
      if (!investmentState) {
        return 0;
      }

      const investmentRef = db.collection('investments').doc(investmentState.id);
      const [getInvestmentError, getInvestment] = await to(investmentRef.get());
      if (getInvestmentError || !getInvestment!.exists) {
        throw Error(getInvestmentError?.message || 'Investment not found');
      }
      const investment = getInvestment!.data() as Investment;

      const assetRef = investment.asset as firebase.firestore.DocumentReference;
      const [getAssetError, getAsset] = await to(assetRef.get());
      if (getAssetError || !getAsset!.exists) {
        throw Error(getAssetError?.message || 'Asset not found');
      }
      const asset = getAsset!.data() as Asset;

      const [getInvestmentPaymentsError, getInvestmentPaymentsSuccess] = await to(
        investmentRef
          .collection('payments')
          .where('providerData.status', '==', PaymentStatus.Paid)
          .where('deleted', '==', false)
          .get(),
      );

      const [getInvestmentEarningsError, getInvestmentEarningsSuccess] = await to(
        investmentRef
          .collection('earnings')
          .where('deleted', '==', false)
          .get(),
      );

      if (getInvestmentPaymentsError || getInvestmentEarningsError) {
        throw new Error(
          getInvestmentPaymentsError?.message
          || getInvestmentEarningsError?.message
          || 'Something went wrong when trying to fetch data for calculating IRR.',
        );
      }
      if (getInvestmentPaymentsSuccess!.empty && getInvestmentEarningsSuccess!.empty) {
        return 0;
      }

      const paymentDate = (payment: Payment): Date => {
        const pDate = moment(payment.paymentDateTime!.toDate());
        if (pDate.date() === 1) {
          pDate.subtract(1, 'day').toDate();
        }

        return pDate.toDate();
      };

      const formattedPayments = getInvestmentPaymentsSuccess!.docs
        .reduce((accumulator: { payments: { amount: number, date: Date }[], shares: number }, doc): typeof accumulator => {
          const payment = doc.data() as Payment;
          accumulator.payments.push({
            amount: -payment.providerData.metadata.euroAmount,
            date: paymentDate(payment),
          });

          if (payment.ended) {
            accumulator.payments.push({
              amount: payment.providerData.metadata.euroAmount,
              date: payment.ended.toDate(),
            });
          } else {
            accumulator.shares += payment.providerData.metadata.sharesAmount;
          }

          return accumulator;
        }, { payments: [], shares: 0 });

      const formattedEarnings = getInvestmentEarningsSuccess!.docs
        .reduce((accumulator: { earnings: { amount: number, date: Date }[] }, doc): typeof accumulator => {
          const earning = doc.data();

          accumulator.earnings.push({
            amount: earning.personalAmount,
            date: earning.period.toDate(),
          });

          return accumulator;
        }, { earnings: [] });

      const transactions: Transaction[] = [
        ...formattedPayments.payments,
        ...formattedEarnings.earnings,
      ].sort((a, b): number => a.date.valueOf() - b.date.valueOf());
      if (transactions.length === 0) {
        return 0;
      }

      transactions.push({
        amount: new BigNumber(formattedPayments.shares).multipliedBy(asset.sharePrice).toNumber(),
        date: moment().toDate(),
      });

      if (!formattedPayments.payments.length || !formattedEarnings.earnings.length) {
        return 0;
      }

      try {
        return new BigNumber(xirr(transactions)).times(100).dp(1).toNumber();
      } catch (e) {
        console.error(e); // eslint-disable-line no-console
        return 0;
      }
    },
  },
  getters: {
    // remember in RAX there is only one asset
    /**
     * Get the interest paid out by asset
     * */
    getPersonalDividendsByAsset: (state): PersonalDividend[] => {
      if (state.status !== 'success') {
        return [];
      }
      return state.payload.filter((dividend: PersonalDividend): boolean => dividend.personalAmount > 0);
    },
    /** Pivot the interest paid out per month
     * sums up the payments of one type per month
     * */
    getPersonalDividendsByAssetPivoted(state, getters): PivotedDividend[] {
      const sortedDivs = [...getters.getPersonalDividendsByAsset];
      const dividendsPerMonth: Required<Pick<PersonalDividend, 'period' | 'personalAmount' | 'type' | 'interestNotice'>>[] = [];
      sortedDivs.forEach(({ period, personalAmount, type, interestNotice, dividend }): void => {
        const prevDiv = dividendsPerMonth[dividendsPerMonth.length - 1];

        if (interestNotice === undefined) {
          // Fix for interestNotice. It seems that in some cases its saved in the dividend subobject
          interestNotice = dividend?.interestNotice || [];
        }
        if (!prevDiv) {
          // this is the first element
          dividendsPerMonth.push({ period, personalAmount, type, interestNotice });
          return;
        }
        const isSameMonth = prevDiv.period.toDate().getMonth() === period.toDate().getMonth() && prevDiv.period.toDate().getFullYear() === period.toDate().getFullYear()
          // type A dividends should also be shown separate
          && type !== 'A' && dividendsPerMonth[dividendsPerMonth.length - 1].type !== 'A';
        if (!isSameMonth) {
          dividendsPerMonth.push({ period, personalAmount, type, interestNotice });
          return;
        }

        dividendsPerMonth[dividendsPerMonth.length - 1] = {
          personalAmount: prevDiv.personalAmount + personalAmount,
          type,
          period,
          interestNotice: prevDiv.interestNotice.concat(interestNotice),
        };
      });
      return dividendsPerMonth;
    },
  },
};
