import Vue from 'vue';
import to from 'await-to-js';
import { Module, ActionContext } from 'vuex';
import type firebase from 'firebase/app';
import { StateSlice, InitialStateSlice, State } from '@/store/models';
import { functions, db } from '@/firebase';
import { CheckoutCloudFunctionParameters } from '@/store/models/checkout';

const PAYMENT_INIT_ERROR = 'PAYMENT_INIT_ERROR';
const PAYMENT_INIT_SUCCESS = 'PAYMENT_INIT_SUCCESS';
const PAYMENT_INIT_PROCESSING = 'PAYMENT_INIT_PROCESSING';
const GET_PAYMENT_ERROR = 'GET_PAYMENT_ERROR';
const GET_PAYMENT_SUCCESS = 'GET_PAYMENT_SUCCESS';
const GET_PAYMENT_PROCESSING = 'GET_PAYMENT_PROCESSING';
const RESET_PAYMENT = 'RESET_PAYMENT';

export interface PaymentInitData {
  redirectUrl: string;
  lang: string;
  brand: string;
  type?: string;
}

export default <Module<StateSlice, State>>{
  state: new InitialStateSlice(),
  mutations: {
    [PAYMENT_INIT_ERROR](state: StateSlice, error: any): void {
      Vue.set(state, 'status', 'error');
      Vue.set(state, 'error', error.message || 'Something went wrong');
    },
    [PAYMENT_INIT_SUCCESS](state: StateSlice, payload: any): void {
      Vue.set(state, 'status', 'success');
      Vue.set(state, 'payload', payload);
    },
    [PAYMENT_INIT_PROCESSING](state: StateSlice): void {
      Vue.set(state, 'status', 'processing');
    },
    [GET_PAYMENT_ERROR](state: StateSlice, error: any): void {
      Vue.set(state, 'status', 'error');
      Vue.set(state, 'error', error.message || 'Something went wrong');
    },
    [GET_PAYMENT_SUCCESS](state: StateSlice, payload: any): void {
      Vue.set(state, 'status', 'success');
      Vue.set(state, 'payload', payload);
    },
    [GET_PAYMENT_PROCESSING](state: StateSlice): void {
      Vue.set(state, 'status', 'processing');
      Vue.set(state, 'payload', null);
    },
    [RESET_PAYMENT](state: StateSlice): void {
      Vue.set(state, 'status', '');
      Vue.set(state, 'payload', null);
      Vue.set(state, 'error', '');
      Vue.set(state, 'name', '');
    },
  },
  actions: {
    async paymentInit(
      { commit, getters }: ActionContext<StateSlice, State>,
      data: PaymentInitData,
    ): Promise<void> {
      commit(PAYMENT_INIT_PROCESSING);
      const dataToSend: CheckoutCloudFunctionParameters = {
        ...data,
        assetId: getters.getCheckout.assetId,
        selectedDividendsFormatYear: getters.getCheckout.selectedDividendsFormatYear,
        sharesAmount: getters.getCheckout.sharesAmount,
        ...(getters.getAssetById(getters.getCheckout.assetId).premium && {
          type: 'loan',
        }),
      };
      /*
      this.$gtm.trackEvent({
      event: 'invest_init',
      email: this.user?.email,
      property: this.asset?.name,
      investment: this.totalEur,
      rent: this.selectedDividendsFormatYear,
    });
    * */
      const [paymentInitError, paymentInitSuccess] = await to(
        functions.httpsCallable('paymentInitTransaction')(dataToSend),
      );
      if (paymentInitError) {
        commit(PAYMENT_INIT_ERROR, paymentInitError);
      } else {
        commit(PAYMENT_INIT_SUCCESS, paymentInitSuccess);
      }
    },
    async getPayment({ commit }: ActionContext<StateSlice, State>, { id }: any): Promise<void> {
      commit(GET_PAYMENT_PROCESSING);
      const [getPaymentError, getPaymentSuccess] = await to(db.collection('payments').doc(id).get());
      if (getPaymentError) {
        commit(GET_PAYMENT_ERROR, getPaymentError);
      } else {
        commit(
          GET_PAYMENT_SUCCESS,
          (getPaymentSuccess as firebase.firestore.DocumentSnapshot).data(),
        );
      }
    },
    resetPayment({ commit }: ActionContext<StateSlice, State>): void {
      commit(RESET_PAYMENT);
    },
  },
};
