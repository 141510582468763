import to from 'await-to-js';
import firebase from 'firebase';
import Vue from 'vue';
import { ActionContext, Module } from 'vuex';
import { Loan } from '@/store/models/Loan';
import { InitialStateSlice, State, StateSlice } from '@/store/models';
import { db } from '@/firebase';
import BigNumber from 'bignumber.js';
import { Asset } from '../models/asset';

const GET_PREMIUM_LOANS_ERROR = 'GET_PREMIUM_LOANS_ERROR';
const GET_PREMIUM_LOANS_SUCCESS = 'GET_PREMIUM_LOANS_SUCCESS';
const GET_PREMIUM_LOANS_PROCESSING = 'GET_PREMIUM_LOANS_PROCESSING';

export default <Module<StateSlice<Loan[]>, State>>{
  state: new InitialStateSlice(),
  mutations: {
    [GET_PREMIUM_LOANS_ERROR](state: StateSlice, error: any): void {
      Vue.set(state, 'status', 'error');
      Vue.set(state, 'error', error.message || 'Something went wrong');
    },
    [GET_PREMIUM_LOANS_SUCCESS](state: StateSlice, payload: any): void {
      Vue.set(state, 'status', 'success');
      Vue.set(state, 'payload', payload);
    },
    [GET_PREMIUM_LOANS_PROCESSING](state: StateSlice): void {
      Vue.set(state, 'status', 'processing');
      Vue.set(state, 'payload', null);
    },
  },
  actions: {
    // gets all loans from invested premium assets
    async getInvestedPremiumAssetLoans({ commit, state }: ActionContext<StateSlice, State>, { investorId }: any): Promise<any> {
      // return null if premium loans already fetched
      if (state?.payload?.length) {
        return null;
      }

      commit(GET_PREMIUM_LOANS_PROCESSING);

      // get investments for user
      const [getInvestmentsError, getInvestmentsSuccess] = await to(
        db.collection('investments')
          .where('investor', '==', db.collection('investors').doc(investorId))
          .get(),
      );

      if (getInvestmentsError) {
        return commit(GET_PREMIUM_LOANS_ERROR, getInvestmentsError);
      }

      const investedAssets = getInvestmentsSuccess!.docs.map((doc): firebase.firestore.DocumentReference => doc.data()?.asset as firebase.firestore.DocumentReference);

      const [getInvestedPremiumAssetLoansError, getInvestedPremiumAssetLoansSuccess] = await to(
        // for every investment
        Promise.all(investedAssets.map(async (doc): Promise<any> => {
          // get asset doc in invested asset
          const [getAssetRefError, getAssetRefSuccess] = await to(doc.get());

          // return null if error or asset doesn't exist
          if (getAssetRefError || !getAssetRefSuccess?.exists) {
            return null;
          }

          const assetData = getAssetRefSuccess!.data() as Asset;

          // return null if not premium
          if (!assetData.premium || !assetData.published || assetData.deleted) {
            return null;
          }

          const [getLoansError, getLoansSuccess] = await to(
            getAssetRefSuccess!.ref
              .collection('loans')
              .where('deleted', '==', false)
              .where('published', '==', true)
              .get(),
          );

          // return null if error or no loans
          if (getLoansError || getLoansSuccess?.empty) {
            return null;
          }

          const loan = getLoansSuccess!.docs[0];

          // return loan data + id and debts data from asset
          return {
            id: loan.id,
            ...loan.data(),
            debts: assetData.debts,
            assetId: getAssetRefSuccess.id,
            totalValueEuro: assetData.totalValueEuro,
          };
        })),
      );

      if (getInvestedPremiumAssetLoansError) {
        return commit(GET_PREMIUM_LOANS_ERROR, getInvestedPremiumAssetLoansError);
      }

      // filter out existing loans
      const loans = getInvestedPremiumAssetLoansSuccess!.filter((val): boolean => val !== null).flat();

      return commit(GET_PREMIUM_LOANS_SUCCESS, loans);
    },
  },
  getters: {
    getPremiumAssetsTotalAmount: (state): number =>
      (state.payload || []).reduce((accumulator, loan): BigNumber => accumulator.plus(loan.totalValueEuro || 0), new BigNumber(0)).toNumber(),
  },
};
