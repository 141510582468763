import Vue from 'vue';
import strings from './string';
import date from './date';
import number from './number';

const filters = [
  strings,
  date,
  number,
];

// Adding the filters globally
filters.forEach((filterGroup): void => {
  Object.entries(filterGroup).forEach(([key, filter]): void => {
    Vue.filter(key, filter);
  });
});
