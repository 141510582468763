/**
 * Exporting all the variables depending if we are in develop or in a brand specific branch
 */

module.exports = {
  logo: 'logo.svg',
  logoalt: 'logo-white.svg',
  logoShare: 'bloqhouse.png',
  scssVariables: './src/scss/variables/brands/_generic.scss',
  name: 'Rax',
  phone: '+31 20 21 01 225',
  email: 'info@raxfinance.nl',
  website: 'https://raxfinance.nl',
  glossary: true,
  modules: {
    landing: false,
    identification: true,
  },
  langFileName: 'rax.ts',
  intercom: false,
  requireContractAgreement: true,
  idinActive: false, // having this active requires further config
  requireLegalBanner: true,
  requireQuestionnaire: true,
};
